<template>
  <v-stepper-items>
    <v-stepper-content step="1">
      <v-card-title>
        {{ $t("collection.editorialPlan.section.contentHeader") }}
      </v-card-title>
      <v-container>
        <v-row>
          <template v-for="(section, i) in sections">
            <v-col :key="i">
              <v-card class="mx-auto" max-width="344" elevation="2">
                <v-img
                  :src="img(section.img)"
                  :title="$t(section.imgTitle)"
                  height="200px"
                  ><div class="fill-height gradient"></div>
                </v-img>
                <v-card style="height: 8em">
                  <v-card-title> {{ $t(section.title) }} </v-card-title>
                  <v-card-subtitle>
                   <span class="editionID">{{  $t(section.editionID)}} </span>{{ $t(section.editionID) }}
                  </v-card-subtitle>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="$emit('next', { step: 1, id: section.id })"
                  >
                    {{ $t("button.continue") }}
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="section.showDetails">
                    <v-divider></v-divider>

                    <v-card-text>
                      {{ $t(section.desc) }}
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-stepper-content>
    <v-stepper-content step="2">
      <v-card-title>
        {{ $t("collection.editorialPlan.volume.contentHeader") }}
      </v-card-title>
      <v-container>
        <v-row>
          <template v-for="(volume, i) in volumes">
            <v-col :key="i" cols="12" md="6" lg="3">
              <v-card class="mx-auto" max-width="344" elevation="2">
                <v-img
                  :src="img(volume.img)"
                  :title="$t(volume.imgTitle)"
                  height="200px"
                  ><div class="fill-height gradient"></div
                ></v-img>
                <v-card :height="$vuetify.breakpoint.mdAndUp ? '13em' : '10em'">
                  <v-card-title class="text-break">
                    {{ $t(volume.title) }}
                  </v-card-title>
                  <v-card-subtitle>
                <span class="editionID">{{  $t(volume.editionID)}} </span>{{ $t(volume.subTitle) }}
                  </v-card-subtitle>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="$emit('next', { step: 2, id: volume.id })"
                  >
                    {{ $t("button.continue") }}
                  </v-btn>                  
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-stepper-content>
    <v-stepper-content step="3">
      <v-card-title>
        {{ $t("collection.editorialPlan.tome.contentHeader") }}
      </v-card-title>
      <template v-if="!tomes">
        <v-card-subtitle>
          Non sono presenti tomi per questo volume
        </v-card-subtitle>
        <v-btn
          color="primary"
          text
          @click="$emit('next', { step: 3, id: 'tome-0' })"
        >
          {{ $t("button.gotoWorks") }}
        </v-btn>
      </template>
      <v-container v-else>
        <v-row>
          <template v-for="(tome, i) in tomes">
            <v-col :key="i">
              <v-card class="mx-auto" max-width="344" elevation="2">
                <v-img
                  :src="img(tome.img)"
                  :title="$t(tome.imgTitle)"
                  height="200px"
                  ><div class="fill-height gradient"></div
                ></v-img>
                <v-card :height="$vuetify.breakpoint.mdAndUp ? '13em' : '10em'">
                  <v-card-title class="text-break">
                    {{ $t(tome.title) }}
                  </v-card-title>
                  <v-card-subtitle v-if="tome.subTitle">
                    <span class="editionID">{{  $t(tome.editionID)}} </span> {{$t(tome.subTitle)}}
                  </v-card-subtitle>
                  <v-card-subtitle v-else>{{
                    $t(tome.editionID)
                  }}</v-card-subtitle>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="$emit('next', { step: 3, id: tome.id })"
                  >
                    {{ $t("button.continue") }}
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="tome.showDetails">
                    <v-divider></v-divider>

                    <v-card-text>
                      {{ $t(tome.desc) }}
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </v-stepper-content>
    <v-stepper-content step="4">
      <div v-if="choosenPath">
        <v-card-title class="text-break text-h5">
          {{ $t(choosenPath.title) }}
        </v-card-title>
        <Citation :title="choosenPath.citation" />
        <v-container class="mb-2 pt-0">
          <v-row>
            <v-col v-for="additionalResource in additionalResources" :key="additionalResource.type">
              <v-card elevation="2" shaped>
                <v-card-title>
                  {{ $t(additionalResource.title) }}
                </v-card-title>
                <v-card-subtitle>
                  {{ $t(choosenPath[additionalResource.subtitle]) }}
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    :href="openFile(additionalResource.type)"
                    target="_blank"                    
                    small          
                    :disabled= !choosenPath[additionalResource.type]
                  >
                    {{ $t(additionalResource.btn) }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn icon v-show="choosenPath[additionalResource.type]" @click="additionalResource.show = !additionalResource.show">
                    <v-icon>{{
                      additionalResource.show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="additionalResource.show">
                    <v-divider></v-divider>
                    <v-card-text
                      class="d-flex align-center text-center font-italic my-2"
                    >
                      "{{ $t(choosenPath[additionalResource.type]) }}"
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <router-view v-if="works.length > 0" :works="works"> </router-view>
        <linear-loading v-else :isLoading="true"></linear-loading>
      </div>
      <div v-else>
        <v-card-title> Prossima pubblicazione </v-card-title>
      </div>
    </v-stepper-content>
  </v-stepper-items>
</template>

<script>
import { mapGetters } from "vuex";
import Citation from "@/components/collection/Citation";
//import axios from 'axios'
//import WorksList from '@/components/collection/works/WorksList'

export default {
  props: ["position", "works"],
  components: {
    Citation,
  },
  data() {
    return {
      additionalResources: [
        {          
          type: "introduction",
          show: false,
          title: "collection.editorialPlan.cards.introTitle",
          subtitle: "introductionSubtitle",
          btn: "collection.editorialPlan.cards.btn.intro"
        },      
        {          
          type: "essay",
          show: false,
          title: "collection.editorialPlan.cards.critic-essayTitle",
          subtitle: "criticEssaySubtitle",
          btn: "collection.editorialPlan.cards.btn.essay"
        }
      ],
      title: "collection.editorialPlan.citation.volume-1",
    };
  },
  computed: {
    ...mapGetters({
      sections: "editorialPlan/getSections",
      volumes: "editorialPlan/getVolumes",
      tomes: "editorialPlan/getTome",
      choosenPath: "descriptions/choosenPath",
    }),
    extraSmallDevices() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    img(image) {
      return require(`@/assets/edition/editorial_plan/${image}`);
    },
    openFile(type) {
      const id = this.$route.params.worksPath;

      return `/additional_resources/?id=${id}&type=${type}`;
    },
  },      
  watch: {
    $route(){
      this.additionalResources.map(additionalResource => additionalResource.show = false);
    }
  }
};
</script>

<style lang="scss" scoped>
.gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(11, 28, 61, 0.9),
    25%,
    rgba(0, 0, 0, 0)
  );
}
</style>