<template>
    <v-card >
        <v-card-text class="pt-0" v-html="$t(title)">
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:['title']
}
</script>
