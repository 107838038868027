<template>  
  <v-stepper elevation="2" v-model="position" @change="goBack($event)">
        <v-overlay
      absolute
      color="secondaryLighten"
      :opacity="opacity"
      :z-index="zIndex"
      :value="isLoading"
    >
      <v-progress-circular
        size="80"
        width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <StepperHeader :position="position" :steps="steps" />
    <StepperItems
      :works="works"
      :position="position"
      v-on:next="nextStep($event)"
    />
  </v-stepper>
</template>

<script>
import StepperHeader from "@/components/collection/StepperHeader";
import StepperItems from "@/components/collection/StepperItems";

export default {
  components: {
    StepperHeader,
    StepperItems,
  },
  data() {
    return {
      opacity: 0.4,
      zIndex: 2,
      isLoading: false,
      position: 1,
      works: [],
      steps: [
        {
          id: 1,
          title: "collection.editorialPlan.section.stepper",
          value: null,
          editable: true,
        },
        {
          id: 2,
          title: "collection.editorialPlan.volume.stepper",
          value: null,
          editable: true,
        },
        {
          id: 3,
          title: "collection.editorialPlan.tome.stepper",
          value: null,
          editable: true,
        },
        {
          id: 4,
          title: "collection.editorialPlan.works.stepper",
          value: null,
          editable: false,
        },
      ],
    };
  },
  methods: {
    async nextStep(params) {
      // push id (section-1,section-2...) on stepperPath array.
      await this.$store.dispatch("editorialPlan/pushStep", params);

      // move the stepper header on the next step
      this.position = params.step + 1;
    },
    async goBack(event) {
      // manually go back on the stepper clear stepperPath
      await this.$store.dispatch("editorialPlan/clearPath", { step: event });
    },
    async restorePath(id) {
      // restore path choices in case of workPath already created in the route params
      let identifier = ["section", "volume", "tome"];
      let params;

      //create params step and id for the stepperPath
      for (let i = 0; i < id.length; i++) {
        params = {
          step: parseInt(i) + 1,
          id: `${identifier[i]}-${id[i]}`,
        };

        await this.$store.dispatch("editorialPlan/pushStep", params);
      }
    },
    async pushRoutePath() {
      // push stepperPath on the router
      const stepperPath = this.$store.getters["editorialPlan/getStepperPath"];
      let path = "";
      let section, volume, tome;

      if (stepperPath[0]) {
        section = `${stepperPath[0].split("-")[1]}`;
        path += section;
      }
      if (stepperPath[1]) {
        volume = `${stepperPath[1].split("-")[1]}`;
        path += volume;
      }
      if (stepperPath[2]) {
        tome = `${stepperPath[2].split("-")[1]}`;
        if(tome == 0){
          this.checkTomesEditable()
        }
        path += tome;
      }
      await this.$store.dispatch("descriptions/changePath", path);

      if (path !== "" && path !== this.$route.params.worksPath) {
        this.$router.push(`/edition/browse/editorial_plan/${path}`);
      }
    },
    async filterWorks(worksPath) {
      if (worksPath) {
        worksPath = `${worksPath[0]}_${worksPath[1]}_${worksPath[2]}`;
        await this.$store.dispatch("works/loadWorks", {
          path: [`${worksPath}`],
        });
        this.works = this.$store.getters["works/works"];
      }
    },
    checkTomesEditable() {
      const tomes = this.$store.getters["editorialPlan/getTome"];
      console.log('TOMES',tomes);
      if (!tomes || tomes.length == 0) {
        this.nextStep({ step: 3, id: "tome-0" });
        this.steps[2].editable = false;
      } else {
        this.steps[2].editable = true;
      }
    },
  },
  watch: {
    // watching the stepper position
    async position(val) {
      switch (val) {
        // Volumes stepper-content
        case 2:
          await this.$store.dispatch("editorialPlan/changeVolumes");
          break;
        case 3:
          // Tomes stepper-content
          await this.$store.dispatch("editorialPlan/changeTomes");
          this.checkTomesEditable();
          break;
        case 4:
          // Works stepper-content
          await this.pushRoutePath();          
          break;
        default:
          break;
      }
    },
    $route() {
      this.filterWorks(this.$route.params.worksPath);
    },
  },
  async created() {
    // if the url already contains the worksPath redirect at the work step
    if (this.$route.params.worksPath) {
      this.isLoading = true
        await this.restorePath(this.$route.params.worksPath);
        await this.filterWorks(this.$route.params.worksPath);
        this.position = 4;
      this.isLoading = false
    }
  },
};
</script>