<template>
    <v-stepper-header>
      <template v-for="step in steps">
        
          <v-stepper-step
          :key="step.id"
          :complete="position > step.id || position == steps.length"
          :step="step.id"
          :editable="position > step.id && step.editable"
        >
        <v-tooltip :disabled="step.id == 4 || step.editable" top>
          <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs"
          v-on="on">{{ $t(step.title) }}</span>
          </template>
          <span>Non sono presenti tomi per questo volume</span>
          </v-tooltip>        
        </v-stepper-step>        
        <v-divider
          v-if="step.id !== steps.length"
          :key="`${step.id}-divider`"
        ></v-divider>
      </template>
    </v-stepper-header>
</template>

<script>
export default {
  props: ["steps","position"]
};
</script>